import { graphQLOperationsInfo } from './APIClient';
import { listFactory } from './operations/list';
import { getFactory } from './operations/get';
import { subscriptionFactory } from './operations/subscription';
import { observeQueryFactory } from './operations/observeQuery';
export function generateModelsProperty(client, params) {
    const models = {};
    const config = params.amplify.getConfig();
    if (!config.API?.GraphQL) {
        // breaks compatibility with certain bundler, e.g. Vite where component files are evaluated before
        // the entry point causing false positive errors. Revisit how to better handle this post-launch
        // throw new Error(
        // 	'The API configuration is missing. This is likely due to Amplify.configure() not being called
        // prior to generateClient().'
        // );
        return {};
    }
    const modelIntrospection = config.API.GraphQL.modelIntrospection;
    if (!modelIntrospection) {
        return {};
    }
    const SUBSCRIPTION_OPS = ['ONCREATE', 'ONUPDATE', 'ONDELETE'];
    for (const model of Object.values(modelIntrospection.models)) {
        const { name } = model;
        models[name] = {};
        Object.entries(graphQLOperationsInfo).forEach(([key, { operationPrefix }]) => {
            const operation = key;
            if (operation === 'LIST') {
                models[name][operationPrefix] = listFactory(client, modelIntrospection, model);
            }
            else if (SUBSCRIPTION_OPS.includes(operation)) {
                models[name][operationPrefix] = subscriptionFactory(client, modelIntrospection, model, operation);
            }
            else if (operation === 'OBSERVE_QUERY') {
                models[name][operationPrefix] = observeQueryFactory(models, model);
            }
            else {
                models[name][operationPrefix] = getFactory(client, modelIntrospection, model, operation);
            }
        });
    }
    return models;
}
