// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { graphql, cancel, isCancelError } from './v6';
import { generateModelsProperty } from './generateModelsProperty';
import { __amplify, __authMode, __authToken, __headers, } from '../types';
/**
 * @private
 *
 * Creates a client that can be used to make GraphQL requests, using a provided `AmplifyClassV6`
 * compatible context object for config and auth fetching.
 *
 * @param params
 * @returns
 */
export function generateClient(params) {
    const client = {
        [__amplify]: params.amplify,
        [__authMode]: params.authMode,
        [__authToken]: params.authToken,
        [__headers]: params.headers,
        graphql,
        cancel,
        isCancelError,
        models: {},
    };
    client.models = generateModelsProperty(client, params);
    return client;
}
